import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller in _annual_upgrade_prompt_modal.html.erb tells Stimulus to connect that element to the annual-upgrade-prompt-modal controller (i.e., this file)
    // The connect method is called automatically when the controller is connected.

    // This tells Stimulus to look for elements with:
    // - data-annual-upgrade-prompt-modal-target="modal" (store it in this.modalTarget)
    // - data-annual-upgrade-prompt-modal-target="upgradeButton" (store it in this.upgradeButtonTarget)
    // - data-annual-upgrade-prompt-modal-target="loadingMessage" (store it in this.loadingMessageTarget)
    // - data-annual-upgrade-prompt-modal-target="successMessage" (store it in this.successMessageTarget)
    // - data-annual-upgrade-prompt-modal-target="errorMessage" (store it in this.errorMessageTarget)
    // - data-annual-upgrade-prompt-modal-target="mainContent" (store it in this.mainContentTarget)
    // - data-annual-upgrade-prompt-modal-target="closeButton" (store it in this.closeButtonTarget)
    static targets = ["modal", "upgradeButton", "loadingMessage", "successMessage", "errorMessage", "mainContent", "closeButton"]

    static values = {
        // This tells Stimulus to look for a data-annual-upgrade-prompt-modal-email-value attribute on the element (and store it in this.emailValue)
        email: String,
        // This tells Stimulus to look for a data-annual-upgrade-prompt-modal-distraction-free-value attribute on the element (and store it in this.distractionFreeValue)
        distractionFree: { type: Boolean, default: false }
    }

    // As soon as Stimulus connects the controller, it automatically calls the connect method
    connect() {
        this.openModal()
    }

    openModal() {
        // Checks if it can find the modal target (the element with data-annual-upgrade-prompt-modal-target="modal")
        if (this.hasModalTarget) {
            // If found, it removes the 'hidden' class, making the modal visible
            this.modalTarget.classList.remove('hidden')
            // Adds 'noscroll' to the body to prevent scrolling while the modal is open
            document.body.classList.add('noscroll')
            // Creates an overlay to cover the entire screen
            this.createOverlay()
            // This updates the upgrade_annual_modal_last_shown_at column on the user record
            this.recordModalShown()
        } else {
            console.log("Modal target not found")
        }
    }

    // This sends a request to the server to update the upgrade_annual_modal_last_shown_at column on the user record
    recordModalShown() {
        axios({
            method: 'post',
            url: '/api/v1/users/update_annual_modal_shown',
            data: {
                //Authenticity token explanation:
                // In redesign2019.html.erb, there is a meta tag that looks like this: <%= csrf_meta_tags %>
                // csrf_meta_tags is a helper method that generates to HTML meta tags in the page's <head> section that look like this:
                //<meta name="csrf-param" content="authenticity_token">
                //<meta name="csrf-token" content="long_random_string_here">
                // The token is sent to the server where the UsersController's 'verify_authenticity_token'
                //...checks it to ensure the request is legitimate and not from a malicious source 
                authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                // Pass the email value to the server
                // This is so we can look up the user record in the database for an assumed user 
                email: this.emailValue
            }
        }).catch(error => {
            console.error('Error recording modal shown time:', error)
        })
    }

    // This sends a request to the server to upgrade the user's subscription to annual billing
    async handleUpgrade(event) {
        event.preventDefault()

        // Show loading state
        this.mainContentTarget.classList.add('hidden')
        this.loadingMessageTarget.classList.remove('hidden')

        try {
            const response = await axios({
                method: 'post',
                url: '/api/v1/subscription_upgrade',
                data: {
                    // See the authenticity_token explanation in the recordModalShown method
                    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
                    email: this.emailValue
                }
            })

            // Show success message
            this.loadingMessageTarget.classList.add('hidden')
            this.successMessageTarget.classList.remove('hidden')

            // If in distraction-free mode (on /upgrade_plan), redirect to dashboard after successful upgrade
            // Otherwise, just close the modal after 3 seconds
            const delay = 3500;
            if (this.distractionFreeValue) {
                setTimeout(() => {
                    window.location.href = '/dashboard'
                }, delay)
            } else {
                setTimeout(() => {
                    this.close()
                }, delay)
            }

        } catch (error) {
            // Show error message
            this.loadingMessageTarget.classList.add('hidden')
            this.errorMessageTarget.classList.remove('hidden')

            // If not in distraction-free mode (on /upgrade_plan), show the close button
            if (!this.distractionFreeValue) {
                this.closeButtonTarget.classList.remove('hidden')
            }
        }
    }

    close() {
        this.modalTarget.classList.add('hidden')
        document.body.classList.remove('noscroll')
        this.removeOverlay()
    }

    createOverlay() {
        if (document.getElementsByClassName('overlay').length === 0) {
            const overlayEl = document.createElement('div')
            overlayEl.classList.add('overlay')

            document.body.appendChild(overlayEl)
        }
    }

    removeOverlay() {
        const overlays = Array.from(document.getElementsByClassName('overlay'))
        overlays.forEach(el => el.remove())
    }
}