import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'trigger'];
  static values = {
    id: String
  };

  connect() {
    if (!window.openReveal) {
      window.openReveal = (id => {
        if (id === this.idValue) {
          this.show();
        }
      }).bind(this);

      window.hideReveal = (id => {
        if (id === this.idValue) {
          this.hide();
        }
      }).bind(this);
    }

    this.hide();
  }

  toggle(event) {
    event?.preventDefault();
    this.contentTarget.classList.toggle('hidden');

    if (this.contentTarget.classList.contains('hidden')) {
      this.triggerTarget.classList.add('fa-chevron-down');
      this.triggerTarget.classList.remove('fa-chevron-up');
    } else {
      this.triggerTarget.classList.add('fa-chevron-up');
      this.triggerTarget.classList.remove('fa-chevron-down');
    }
  }

  show(event) {
    event?.preventDefault();
    this.contentTarget.classList.remove('hidden');

    this.triggerTarget.classList.add('fa-chevron-up');
    this.triggerTarget.classList.remove('fa-chevron-down');
  }

  hide(event) {
    event?.preventDefault();
    this.contentTarget.classList.add('hidden');

    this.triggerTarget.classList.add('fa-chevron-down');
    this.triggerTarget.classList.remove('fa-chevron-up');
  }
}
