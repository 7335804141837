import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  scrollToHash(evt) {
    if(evt?.detail?.preventScroll) {
      return;
    }

    if(location.hash !== null && location.hash.trim !== "") {
      document.querySelector(location.hash)?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
}