import { Controller } from '@hotwired/stimulus';
import { updatePopups } from '../helpers/popup_helper';

export default class extends Controller {
  static targets = ['content'];
  static values = {
    open: Boolean,
    reloadOnClose: Boolean
  };

  connect() {
    this.openValue ? this.open() : this.contentTarget.classList.add('hidden');
    this.handleKeyUp = this.handleKeyUp.bind(this);
    window.addEventListener('keyup', this.handleKeyUp);
  }

  disconnect() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp(event) {
    if (event.key !== 'Escape') return;
    this.close();
  }

  open(event) {
    event?.preventDefault();

    if (this.hasContentTarget) {
      ReactRailsUJS.mountComponents(this.contentTarget);
    }

    ReactRailsUJS.detectEvents();

    if (document.getElementsByClassName('overlay').length === 0) {
      const overlayEl = document.createElement('div');
      overlayEl.classList.add('overlay');
      overlayEl.addEventListener('click', this.close.bind(this));
      document.body.appendChild(overlayEl);
      document.body.classList.add('noscroll');

      if (this.hasContentTarget) {
        this.contentTarget.classList.remove('hidden');
      }
    }
    updatePopups();
  }

  close(event) {
    event?.preventDefault();
    document.body.classList.remove('noscroll');

    if (this.hasContentTarget) {
      ReactRailsUJS.unmountComponents(this.contentTarget);
    }

    if (this.reloadOnCloseValue) {
      window.location.reload();
    } else {
      const overlays = Array.from(document.getElementsByClassName('overlay'));
      overlays.forEach(el => el.remove());

      if (this.hasContentTarget) {
        this.contentTarget.classList.add('hidden');
      }
    }
  }
}
