import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // When the page loads, Stimulus looks for elements with data-controller="lazy-load" attribute
    // This controller is designed to work with turbo-frame elements to load their content on demand

    // We are using lazy loading to prevent someone from being added as a participant in the ama_promo_in_modal field test without first seeing the modal

    // The data-controller="lazy-load" attribute is set on the turbo-frame in the following files:
    //- /app/views/redesign2019/aliquot_episodes/index.html.erb
    //- /app/views/aliquot_subscriptions/new.html.erb
    //- /app/views/membership_trial_subscriptions/new.html.erb

    // This tells Stimulus to look for a data attribute called data-lazy-load-url-value
    // The value of this attribute will be stored in this.urlValue
    // In the HTML, we set it like this: 
    // - data-lazy-load-url-value="/aliquot_subscription"
    // - data-lazy-load-url-value="/membership_trial_subscription?source=<%= source %>"
    static values = {
        url: String
    }

    // This method is called manually in the open() method of the core_modal_component_controller
    // It's not automatically called by Stimulus like connect()
    load() {
        // 'this.element' refers to the HTML element this controller is attached to
        // In this case, it should be a turbo-frame element
        const turboFrame = this.element

        // Check if the turbo-frame already has a 'src' attribute
        if (turboFrame.src) {
            // If 'src' is already set, it means the content has been loaded or is loading
            // In this case, we don't want to load it again, so we just return
            return
        }

        // If we've reached this point, it means the content hasn't been loaded yet

        // Set the 'src' attribute of the turbo-frame to the URL we want to load
        // this.urlValue comes from the data-lazy-load-url-value attribute we set in the HTML
        turboFrame.src = this.urlValue

        // When we set the 'src' attribute, Turbo will automatically:
        // 1. Make a request to the URL specified in 'src'
        // 2. Replace the content of the turbo-frame with the response from that request
        // This allows us to load content on demand, rather than loading everything when the page first loads
    }
}