import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messageText", "messageCharacterCount", "newsletter"];

  connect() {
    this.updateCharacterCount();
    this.messageTextTarget.addEventListener("input", () => this.updateCharacterCount());
  }

  updateCharacterCount() {
    const message = this.messageTextTarget.value;
    const maxLength = 3000;
    const remaining = maxLength - message.length;
    this.messageCharacterCountTarget.textContent = `You have a maximum of ${remaining} character(s) left.`;

    if (message.length >= 750) {
      this.messageCharacterCountTarget.style.color = "darkred";
      this.messageCharacterCountTarget.textContent += " Getting a bit wordy!";
    } else if (message.length >= 500) {
      this.messageCharacterCountTarget.style.color = "red";
    } else {
      this.messageCharacterCountTarget.style.color = "";
    }
  }

  updateCheckbox() {
    this.newsletterTarget.click();
  }
}