import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // Purpose of this controller: An HTMLSnippet entry (a button) that's added to an episode summary after the timeline preview that switches the user to the full timeline tab

    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller="tab-switcher" in the HTMLSnippet entry tells Stimulus to connect that element to this controller

    // This tells Stimulus to look for elements with data-tab-switcher-target="button" (also within the HTMLSnippet entry)
    // These elements will be accessible via this.buttonTarget
    static targets = ["button"]

    // This method is called when the button is clicked
    // This is because of the data-action="click->tab-switcher#switchTab" attribute in the HTMLSnippet entry
    switchTab(event) {
        // Prevent the default button click behavior (e.g., page reload)
        event.preventDefault()

        // Get the name of the target tab from the button's data-target-tab attribute
        // This is set in the HTML like: data-target-tab="Timeline"
        const targetTab = this.buttonTarget.dataset.targetTab

        // Query the DOM for all tab elements (based on code in tab_pane_component.html.erb)
        // This selector looks for elements that:
        // 1. Have the data-tab-label attribute
        // 2. Have the data-index attribute
        // 3. Are not hidden (not .sr-only class)
        const allTabs = document.querySelectorAll('[data-tab-label="true"][data-index]:not(.sr-only)')

        // Find the tab element that matches the target tab name
        // We use Array.from() to convert the NodeList to an array so we can use .find()
        // We trim the text content to remove any leading/trailing whitespace
        const tabLink = Array.from(allTabs).find(tab => tab.textContent.trim() === targetTab)

        if (tabLink) {
            // If we found a matching tab, programmatically click it
            // This should trigger the tab switch in your existing tab component
            tabLink.click()

            // Find the container element by the episode_tabs class
            const container = document.querySelector('.episode_tabs')

            if (container) {
                // Scroll to the container after a short delay to allow for any DOM updates
                setTimeout(() => {
                    container.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 100)
            } 

        } else {
            // If we didn't find a matching tab, log a warning
            console.warn(`Tab '${targetTab}' not found`)
        }
    }
}