import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    enter: String
  };

  connect() {
    this.element.addEventListener('keyup', function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById(this.enterValue).click();
      }
    });
  }

  disconnect() {
    this.element.removeEventListener('keyup');
  }
}
