import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String
  };

  connect() {
    window.location = this.urlValue;
    history.pushState(this.urlValue);
  }
}
