import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // When the page loads, Stimulus looks for elements with data-controller attribute
  // The data-controller="amount-picker" in amount_picker_component.html.erb tells Stimulus to connect that element to this controller
  // The connect method is called automatically when the controller is connected.

  // This tells Stimulus to look for elements with:
  // - data-amount-picker-target="amountInput" (this is actually passed as amount_picker_target: "amountInput" as part of a data object)
  // - data-amount-picker-target="membershipDescription"
  // These elements will be accessible as this.membershipDescriptionTarget and this.amountInputTargets
  static targets = ["amountInput", "membershipDescription"]

  static values = {
    // This tells Stimulus to look for a data attribute called data-amount-picker-hangout-amount-value
    // The value of this attribute will be automatically stored in this.hangoutAmountValue
    // In amount_picker_component.html.erb, we set it like this: data-amount-picker-hangout-amount-value="<%= App::MembershipFormComponent::HANGOUT_AMOUNT %>"
    hangoutAmount: Number,

    // This tells Stimulus to look for a data attribute called data-amount-picker-default-amounts-value
    // The value of this attribute will be automatically stored in this.defaultAmountsValue
    // In amount_picker_component.html.erb, we set it like this: data-amount-picker-default-amounts-value="<%= calculated_amount_records.map(&:final_amount) %>"
    // It's an array of the amounts that are available to choose from (ex. [1000, 1500, 2500, 5000, 12500] on /crowdsponso or [1000, 25000] on /crowdsponsor/earlybird)
    defaultAmounts: Array
  }

  // We only want to show the membership description *after* the amount has changed once
  // So we set this to false by default and only set it to true when the amount changes
  hasAmountChanged = false

  // As soon as Stimulus connects the controller, it automatically calls the connect method.
  connect() {
    // When the controller connects, we immediately update the membership description
    this.updateMembershipDescription()
  }

  // This function updates the membership description based on the selected amount in amount_picker_component.html.erb
  updateMembershipDescription() {
    // Find the selected radio button (the one that's checked) within amount_picker_component.html.erb and get its value
    const selectedAmount = parseInt(this.amountInputTargets.find(input => input.checked).value)

    // If we have a element with data-amount-picker-target="membershipDescription" (we do, in amount_picker_component.html.erb)
    if (this.hasMembershipDescriptionTarget) {
      let text = ''

      // If the amount has changed, we show the membership description
      // Otherwise, we keep the membership description hidden
      let isVisible = this.hasAmountChanged

      // If the selected amount is equal to the hangout amount (25000)
      if (selectedAmount === this.hangoutAmountValue) {
        text = 'The <strong>monthly hangout</strong> is a small group conversation, live with Rhonda Patrick, webcams on.'
      } else {
        // Base text for Premium Membership
        const baseText = 'Premium Membership with access to <strong>The Aliquot</strong> podcast, monthly <strong>AMAs with Rhonda</strong>, a biweekly <strong>Science Digest</strong> newsletter, and <strong>enhanced show notes</strong>'

        // If the selected amount is the first value in the this.defaultAmountsValue array
        // As of August 2024, this is typically 1000 or 1500
        if (selectedAmount === this.defaultAmountsValue[0]) {
          text = `${baseText}.`
          // If the selected amount is not the first or last value in the this.defaultAmountsValue array, we add "with added good vibes!" to the text
          // As of August 2024, this is typically 2500, 5000, and 12500
        } else if (this.defaultAmountsValue.slice(1, -1).includes(selectedAmount)) {
          text = `${baseText} — with added good vibes!`
          // If the selected amount is none of the above, we hide the membership description (this should not happen)
        } else {
          isVisible = false
        }
      }

      // Populate the hangout text element with the text
      this.membershipDescriptionTarget.innerHTML = text
      // Toggle its visibility based on whether the hangout amount is selected
      this.membershipDescriptionTarget.classList.toggle('hidden', !isVisible)
    }
  }

  // This method is called when an amount radio input is clicked in amount_picker_component.html.erb
  // It's connected to the radio buttons via the data-action attribute in amount_picker_component.html.erb (passed as action: "change->amount-picker#amountChanged")
  amountChanged() {
    // If the amount has not changed yet, we set this.hasAmountChanged to true
    // Setting it to true allows us to show the membership description after the amount has changed once
    if (!this.hasAmountChanged) {
      this.hasAmountChanged = true
    }

    // When the amount changes, we update the membership description
    this.updateMembershipDescription()
  }
}
