import { Controller } from "@hotwired/stimulus"

// This controller manages the button and email input in the newsletter modal for the 'bdnf_enhanced' variant of the newsletter_modal_v3 field test
// When the button is clicked for the first time, it "slides" to the right, revealing the email input field behind it
// The button then becomes the submit button for the form

const buttonTextWhenFormRevealed = 'Download Blueprint'
const buttonTextWhenFormRevealedSmallScreen = 'Download'
const buttonTextWhenFormNotRevealed = "Get the 9-Page Guide With Rhonda's Protocols"
const buttonTextWhenFormNotRevealedSmallScreen = 'Get the 9-Page Guide'

export default class extends Controller {
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller="newsletter-modal" in newsletter_modal_component.html.erb tells Stimulus to connect that element to this controller

    // This tells Stimulus to look for elements with:
    // - data-newsletter-modal-target="emailInput" (this is passed as 'newsletter_modal_target': "emailInput" as part of a data object on the EmailVerificationInputComponent)
    //
    // - data-newsletter-modal-target="submitButton" (this is passed as 'newsletter_modal_target': "submitButton" as part of a data object on the submit button)
    //
    // - data-newsletter-modal-target="form" (this is passed as 'newsletter_modal_target': "form" as part of a data object on the form)
    //
    // These elements will be accessible as this.emailInputTarget, this.submitButtonTarget and this.formTarget
    static targets = ["emailInput", "submitButton", "form"]

    // The connect method is called automatically when the controller is connected
    // It's used to initialize the hasRevealed flag and disable the email input field initially
    connect() {
        // Initially, the email input form is not revealed
        this.hasRevealed = false

        // Disable the email input field initially.
        this.emailInputTarget.disabled = true

        // Update the button text (it changes based on the window's width)
        this.updateButtonText();

        // Add resize event listener
        // This is used to update the button text based on the window's width
        window.addEventListener('resize', this.updateButtonText.bind(this));
    }

    // Remove the resize event listener when the controller is disconnected
    disconnect() {
        window.removeEventListener('resize', this.updateButtonText.bind(this));
    }

    // This method updates the button text based on the window's width
    updateButtonText() {
        let buttonText;

        if (this.hasRevealed) {
            // If the form has been revealed, the button text should always prompt the user to download 
            buttonText = window.innerWidth < 475 ? buttonTextWhenFormRevealedSmallScreen : buttonTextWhenFormRevealed;
        } else {
            buttonText = window.innerWidth <= 490 ? buttonTextWhenFormNotRevealedSmallScreen : buttonTextWhenFormNotRevealed;
        }
        this.submitButtonTarget.textContent = buttonText;

        // When the button text changes, we need to update the right padding on the email input
        // This right padding prevents the text the user is typing from going "behind" the button
        this.setPaddingRightOnEmailInput();
    }

    // The revealForm method is triggered by the data-action attribute on the button
    // It's called when the user clicks the button for the first time
    revealForm(event) {
        // Prevent the default form submission.
        event.preventDefault()

        // Check if the form was already revealed
        // This isn't really need because we remove the data-action attribute from the submit button below (after this function runs for the first time)
        // But leaving it in as a safeguard
        if (this.hasRevealed) return

        // Add the 'slide-right' class to the button 
        this.submitButtonTarget.classList.add("slide-right")

        // Add the 'display' class to the email input field 
        // This changes the opacity to 1 so the email input field is visible
        this.emailInputTarget.classList.add("display")

        // Enable the email input field (we disabled it in the connect function)
        this.emailInputTarget.disabled = false
        // Focus the email input field so the user can start typing
        this.emailInputTarget.focus()

        // Change the button text
        this.submitButtonTarget.textContent = window.innerWidth < 475 ? buttonTextWhenFormRevealedSmallScreen : buttonTextWhenFormRevealed;

        // We just changed the button text, so we need to update right padding on the email input
        // This padding prevents the text the user is typing from going "behind" the button
        this.setPaddingRightOnEmailInput();

        // Mark that the form has been revealed
        this.hasRevealed = true

        // Add attributes back to the form
        // These allow the email_veryify_controller & inline_signup_controller to work again, which allow for email verification
        this.formTarget.dataset.controller = 'inline-signup'
        this.formTarget.dataset.action = 'email-verify:verify->inline-signup#updateEnabledState'

        // Remove the data-action attribute that called this function on the button
        // This is done to prevent this function from being called again after the email form has been revealed
        this.submitButtonTarget.removeAttribute('data-action')

        // Disable the submit button
        // The user hasn't entered an email yet, so the submit button should be disabled
        // The inline_signup_controller will enable the button when the user enters a valid email
        this.submitButtonTarget.disabled = true
    }

    setPaddingRightOnEmailInput() {
        // Get the current width of the button
        const buttonWidth = this.submitButtonTarget.offsetWidth;

        // Set the padding-right of the email input to match the button's width plus 10px
        // This padding prevents the text the user is typing from going "behind" the button
        this.emailInputTarget.style.paddingRight = `${buttonWidth + 10}px`;
    }
}