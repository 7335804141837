import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'results'];

  search() {
    fetch(`/topics/search?query=${encodeURIComponent(this.inputTarget.value)}`)
      .then(response => response.ok ? response.text() : Promise.reject('Failed to load'))
      .then(html => this.resultsTarget.innerHTML = html)
      .catch(error => console.error('Error:', error));
  }
}
