import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleable'];

  static values = {
    open: { type: Boolean, default: false }
  };

  open(evt) {
    // This block handles the special case for the "Click here to login" link
    // in the _gated_aliquot.html.erb file
    if (evt.target.tagName === 'A' && evt.target.getAttribute('data-turbo') === 'false') {
      // If the clicked element is a link (<a> tag) AND has data-turbo="false"...
      return; // ...do nothing, allowing the default link behavior (navigation)
    }

    // For all other cases (i.e., not the login link)...
    evt.preventDefault(); // Prevent the default action (like following a link)
    this.openValue = true; // Open the modal

    // Find all lazy-load controllers inside this modal and load their content
    // These are in:
    //- /app/components/app/episode_player_component/blocked_content_component.html.erb
    //- /app/views/aliquot_subscriptions/new.html.erb
    // We are using lazy loading to prevent someone from being added as a participant in the ama_promo_in_modal field test without first seeing the modal
    this.element.querySelectorAll('[data-controller="lazy-load"]').forEach((el) => {
      const controller = this.application.getControllerForElementAndIdentifier(el, 'lazy-load');
      if (controller) {
        controller.load();
      }
    });
  }

  dismiss(evt) {
    evt.preventDefault();
    this.openValue = false;
  }

  // We have to have something to call when using the :stop modifier
  // ont the click action.  This is it.
  noop() { }

  openValueChanged(open) {
    if (open) {
      this.toggleableTargets.forEach(el => el.classList.remove('hidden'));
    } else {
      this.toggleableTargets.forEach(el => el.classList.add('hidden'));
    }
  }
}