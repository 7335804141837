import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    duration: Number,
    url: String,
    forceRedirect: Boolean
  };

  connect() {
    this.counter = 0;
    this.oldHTML = this.element.innerHTML;

    this.tick();
    this.interval = setInterval(this.tick.bind(this), 1000);
  }

  tick() {
    if (this.element.offsetParent === null) return;

    if (this.counter == this.durationValue + 1) {

       // If not forcing redirect, show the 'X' (close button)
       // Use case: For the delinquent subscription modal, only when the user is trying to view premium content, we don't want to show the X after the timer hits 0
      if (!this.forceRedirectValue) {
        this.element.innerHTML = this.oldHTML;
      }

      clearInterval(this.interval);

      window.location.href = this.urlValue;
    } else {
      this.element.innerHTML = this.durationValue - this.counter;
      this.counter++;
    }
  }
}
