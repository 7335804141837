import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['featureToggle', 'featuredPanel'];

  connect() {
    this.toggleFeature();
  }

  toggleFeature() {
    const { checked } = this.featureToggleTarget;
    this.featuredPanelTarget.hidden = !checked;
  }
}
