import axios from 'axios';

export default class UsernameVerifier {
  username           = null;
  cancelTokenSource  = null;

  cancel() {
    this.cancelTokenSource?.cancel();
  }

  verify(username) {
    if(username && username !== this.username) {

      // cancel the previous call.
      this.cancel();
      this.username           = username;
      this.cancelTokenSource  = axios.CancelToken.source();

      return new Promise((resolve, reject) => {
        axios
        .get(`/username_validation?username=${encodeURIComponent(username)}`, {
          cancelToken: this.cancelTokenSource.token
        })
        .then(({ data }) => {
          if (data.isUsernameValid) {
            resolve();
          } else {
            reject(data.suggestion);
          }
        })
        .catch(() => console.log('request cancelled'));
      });
    }
  }
}
