import { Controller } from '@hotwired/stimulus';
import { positionElementRelativeTo } from '../utils/positioning';
import { debug } from 'ahoy.js';

// --------------------------------------------------------
// Internal Functions
// --------------------------------------------------------

function toggleElement(element) {
  element.classList?.toggle('hidden');
  element.classList?.toggle('absolute');
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['content'];

  connect() {
    this.contentTargetMap = this.contentTargets.reduce((map, target) => {
                              const { popupContentId } = target.dataset;
                              return { ...map, [popupContentId]: target };
                            }, {});
    this.visible = false;
    this.corrected = false;
    this.debug = false;
    this.lastEvent = new Date();
    this.threshold = 1; // 250 ms = 0.25 seconds

    this.idealWidth = this.data.get('idealWidth');
  }

  show(evt) {
    if(!this.hasEnoughTimeElapsed()) { return };
    this.debugLog(`show(evt)`);
    if(this.visible) { return; }
    this.visible = true;
    this.corrected = false;

    const { contentId }   = evt.params;
    const contentElement  = this.contentTargetMap[contentId];
    
    this.defaultAlign(contentElement);

    requestAnimationFrame(() => {
      if(this.corrected) { return; }

      const boundsCheck = this.checkElementBounds(contentElement);
      
      if (boundsCheck.beyondLeft || boundsCheck.beyondRight) {
        this.debugLog(`popup is out of bounds: ${boundsCheck}`);
        this.centerAlign(contentElement);
        this.corrected = true;
      }
    });
    
    toggleElement(contentElement);
  }
  
  hide(evt) {
    if(!this.hasEnoughTimeElapsed()) { return };

    this.debugLog(`hide(evt)`);
    if(!this.visible) { return; }
    this.visible = false;
    this.corrected = false;

    const { contentId }   = evt.params;
    const contentElement  = this.contentTargetMap[contentId];

    toggleElement(contentElement);
  }

  checkElementBounds(element) {
    // Get the bounding rectangle of the element
    const rect = element.getBoundingClientRect();
    
    // Get the window's width
    const windowWidth = window.innerWidth;

    // Check if the element goes beyond the left side of the window
    const beyondLeft = rect.left < 0;

    // Check if the element goes beyond the right side of the window
    const beyondRight = rect.right > windowWidth;

    // Return an object indicating whether the element is beyond the left or right side
    return { beyondLeft, beyondRight };
  }

  // Uses CSS to align the provided element 
  defaultAlign(contentElement) {
    this.debugLog(`defaultAlign(contentElement) - idealWidth: ${this.idealWidth}`);
    contentElement.style.maxWidth = '';
    contentElement.style.width = this.idealWidth || '';
    contentElement.style.left = '-50%';
    contentElement.style.right = '50%';
    contentElement.style.bottom = '100%';
  }

  // Uses CSS to ensure the element doesn't bleed over the edges
  centerAlign(contentElement) {
    this.debugLog(`leftAlign(contentElement) - idealWidth: ${this.idealWidth}`);
    contentElement.style.maxWidth = '80vw';
    contentElement.style.width = '';
    contentElement.style.left = '';
    contentElement.style.right = '';
    contentElement.style.bottom = '100%';
  }


  // Has the threshold amount of time elapsed yet?  Every time you
  // call this function, it will update the `lastEvent` time to now.
  hasEnoughTimeElapsed() {
    const result = (new Date() - this.lastEvent) > this.threshold;
    this.lastEvent = new Date();
    return result;
  }

  debugLog(message) {
    if(this.debug) {
      console.log(`DEBUG: ${message}`);
    }
  }
}