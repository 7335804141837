import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets  = ['emailInput', 'confirmInput', 'submitButton'];
  static values   = { expectedEmail: String };
  
  updateState() {
    const actual        = this.emailInputTarget.value.toLowerCase();
    const expected      = this.expectedEmailValue.toLowerCase();
    const isConfirmed   = this.confirmInputTarget.checked;
    const enableSubmit  = actual === expected && isConfirmed;
    
    this.submitButtonTarget.disabled = !enableSubmit;
  }
}