import { Controller } from '@hotwired/stimulus';

const style = {
  base: {
    color: '#425a73',
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
    lineHeight: '21px',
    '::placeholder': {
      color: '#a0aec0'
    }
  }
};

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['paymentElement', 'errors', 'token', 'zipcode'];

  static values = {
    publicKey: String,
    token: {
      type: Object,
      default: {
        id: null,
        card: { address_zip: null }
      }
    }
  };

  initialize() {
    this.stripe = window.Stripe(this.publicKeyValue);
    this.cardElement = this.stripe.elements().create('card', { style });
  }

  connect() {
    this.cardElement.mount(this.paymentElementTarget);
    this.cardElement.on('change', this.onCardChange.bind(this));
  }

  async onCardChange({ complete, error }) {
    this.errorsTarget.textContent = error?.message || '';

    if(complete) {
      this.dispatch('stripe-token-requested');

      const { token, error } = await this.stripe.createToken(this.cardElement);

      this.errorsTarget.textContent = error?.message || '';
      this.tokenValue = token;

      this.dispatch('stripe-token-value-changed', { detail: { token } });
    }
  }

  tokenValueChanged(token) {
    if(token) {
      this.tokenTarget.value    = token.id;
      this.zipcodeTarget.value  = token.card?.address_zip;
    }
  }
}
