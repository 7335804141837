import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static values = { id: Number };

  close() {
    if (this.idValue) {
      axios.post(`/notifications/${this.idValue}/mark_as_read`)
        .then(() => {
          this.element.remove();
        })
        .catch(() => {
          this.element.remove();
        })
    } else {
      this.element.remove();
    }
  }
}
