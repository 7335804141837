import { Controller }       from '@hotwired/stimulus';
import { UsernameVerifier } from 'redesign2019/app/utils';

const MISSING_SUGGESTION  = '161803';
const USERNAME_EXPR       = /^[a-z]+[a-z0-9]{2,}$/i;
const isValidUsername     = username => USERNAME_EXPR.test(username);
export default class extends Controller {
  static targets = ['loading', 'error', 'suggestion', 'input', 'suggested'];

  static values = {
    isVerifying:  { type: Boolean,  default: false },
    isValid:      { type: Boolean,  default: false },
    suggestion:   { type: String,   default: '' }
  };

  usernameVerifier = new UsernameVerifier();

  async verifyUsername(value) {
    this.isValidValue = false;

    const call = async () => {
      this.isVerifyingValue = true;

      try {
        await this.usernameVerifier.verify(value);
        this.isValidValue = true;
      } catch (replacement) {
        this.isValidValue     = false;
        this.suggestionValue  = replacement || MISSING_SUGGESTION;
      }

      this.isVerifyingValue = false;
    };
    await call();
  }

  async verify(evt) {
    const { value }       = this.inputTarget;
    this.suggestionValue  = '';

    if (isValidUsername(value)) {
      await this.verifyUsername(value);
    }
  }

  acceptSuggestion(evt) {
    evt?.preventDefault();
    this.inputTarget.value  = this.suggestionValue;
    this.suggestionValue    = '';
    this.isValidValue       = true;
  }

  isVerifyingValueChanged(isVerifying) {
    if(isVerifying) {
      this.loadingTarget.classList.remove('hidden');
      this.errorTarget.classList.add('hidden');
      this.suggestionTarget.classList.add('hidden');
    } else {
      this.loadingTarget.classList.add('hidden');
    }
  }

  isValidValueChanged(isValid) {
    this.dispatch("verify", { detail: { isValid } });

    if(isValid) {
      this.errorTarget.classList.add('hidden');
      this.suggestionTarget.classList.add('hidden');
      this.loadingTarget.classList.add('hidden');
    } else if(this.suggestionValue) {
      this.errorTarget.classList.remove('hidden');
    }
  }

  suggestionValueChanged(suggestion) {
    const invalidWithoutSuggestion = suggestion === MISSING_SUGGESTION;

    if (invalidWithoutSuggestion) {
      this.errorTarget.classList.remove('hidden');
      this.suggestionTarget.classList.add('hidden');
    } else {
      this.errorTarget.classList.add('hidden');

      if(suggestion) {
        this.suggestedTarget.innerText = suggestion;
        this.suggestionTarget.classList.remove('hidden');
      } else {
        this.suggestionTarget.classList.add('hidden');
      }
    }
  }
}
