import { Controller }     from '@hotwired/stimulus';
import { EmailVerifier }  from 'redesign2019/app/utils';

// Arbitrary value for indicating no suggestion was given on an invalid email
// address.
const MISSING_SUGGESTION = '314159';

const EMAIL_EXPR = /^[a-z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i;

const isValidFormat = email => EMAIL_EXPR.test(email);

export default class extends Controller {
  static targets = [
    'loading',
    'error',
    'suggestion',
    'input',
    'suggested',
    'ignore'
  ];

  static values = {
    isVerifying:  { type: Boolean,  default: false },
    isValid:      { type: Boolean,  default: false },
    suggestion:   { type: String,   default: '' }
  };

  emailVerifier = new EmailVerifier();

  async verifyEmail(value) {
    this.isValidValue = false;

    const call = async () => {
      this.isVerifyingValue = true;

      try {
        await this.emailVerifier.verify(value);
        this.isValidValue = true;
      } catch ({ replacement }) {
        this.isValidValue     = false;
        this.suggestionValue  = replacement || MISSING_SUGGESTION;
      }

      this.isVerifyingValue = false;
    };
    await call();
  }

  async verify(evt) {
    const { value }       = this.inputTarget;
    this.suggestionValue  = '';

    if (isValidFormat(value)) {
      await this.verifyEmail(value);
    }
  }

  acceptSuggestion(evt) {
    evt?.preventDefault();

    if(this.suggestionValue) {
      this.inputTarget.value  = this.suggestionValue;
      this.suggestionValue    = '';
      this.isValidValue       = true;
    }
  }

  rejectSuggestion(evt) {
    evt?.preventDefault();
    this.suggestionValue    = '';
    this.isValidValue       = true;
  }

  isVerifyingValueChanged(isVerifying) {
    if(isVerifying) {
      this.loadingTarget.classList.remove('hidden');
      this.errorTarget.classList.add('hidden');
      this.suggestionTarget.classList.add('hidden');
    } else {
      this.loadingTarget.classList.add('hidden');
    }
  }

  isValidValueChanged(isValid) {
    this.dispatch("verify", { detail: { isValid } });

    if(isValid) {
      this.errorTarget.classList.add('hidden');
      this.suggestionTarget.classList.add('hidden');
      this.loadingTarget.classList.add('hidden');
    } else if(this.suggestionValue) {
      this.errorTarget.classList.remove('hidden');
    }
  }

  suggestionValueChanged(suggestion) {
    const invalidWithoutSuggestion = suggestion === MISSING_SUGGESTION;

    if (invalidWithoutSuggestion) {
      this.errorTarget.classList.remove('hidden');
      this.suggestionTarget.classList.add('hidden');
    } else {
      this.errorTarget.classList.add('hidden');

      if(suggestion) {
        this.suggestedTarget.innerText = suggestion;
        this.suggestionTarget.classList.remove('hidden');
      } else {
        this.suggestionTarget.classList.add('hidden');
      }
    }
  }
}
