import axios from 'axios';

const validationSources = {
  'qev': (result) => {
    const { accept_all,
            did_you_mean: replacement,
            valid } = result;

    return { valid, accept_all, replacement };
  },

  'email_inquire': (result) => {
    const { status, replacement } = result;
    const valid                   = status === 'valid' || status === 'hint';
    const accept_all              = false;

    return { valid, accept_all, replacement };
  }
};

function validate(data) {
  const { source, result } = data;
  return validationSources[source](result);
}

// -----------------------------------------------------
// Default Export
// -----------------------------------------------------

// Manages API call cancellations.
export default class EmailVerifier {
  email              = null;
  cancelTokenSource  = null;

  cancel() {
    this.cancelTokenSource?.cancel();
  }

  verify(email) {
    if(email && email !== this.email) {

      // cancel the previous call.
      this.cancel();
      this.email              = email;
      this.cancelTokenSource  = axios.CancelToken.source();

      return new Promise((resolve, reject) => {
        axios
        .get(`/email_typo_check/${btoa(encodeURIComponent(email))}`, {
          cancelToken: this.cancelTokenSource.token
        })
        .then(({ data }) => {
          const { valid, ...rest } = validate(data);
          if (valid) {
            resolve(rest);
          } else {
            reject(rest);
          }
        })
        .catch(() => console.log('request cancelled'));
      });
    }
  }
}
