import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['target'];

  static values = {
    delay: { type: Number, default: 0 },
    shouldSetCookie: { type: Boolean, default: false }
  }

  connect() {
    setTimeout(() => {
      if (this.shouldSetCookieValue) {
        this.setCookie();
      };
      this.targetTarget.click();
    }, this.delayValue);
  }

  setCookie() {
    document.cookie = "newsletter_modal_dismissed=true";
  }
}
