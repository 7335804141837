import { Controller } from '@hotwired/stimulus';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

function createHiddenField(name, value) {
  const hiddenInput = document.createElement('input');

  hiddenInput.setAttribute('type',  'hidden');
  hiddenInput.setAttribute('name',  name);
  hiddenInput.setAttribute('value', value);

  return hiddenInput;
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class StripeController extends Controller {
  static values = { 
    tokenInputName: String,
    zipInputName:   String,
    publicKey:      String,
    style:          Object
  };

  static targets = ['form', 'card', 'errors'];

  connect() {
    const publicKey   = this.publicKeyValue;
    this.stripe       = window.Stripe(publicKey);
    this.onCardChange = this.onCardChange.bind(this);

    this.tokenInput   = null;
    this.zipInput     = null;

    const elements  = this.stripe.elements();
    const style     = this.styleValue;

    this.card = elements.create('card', { style });
    this.card.on('change', this.onCardChange);
    this.card.mount(this.cardTarget);
  }

  setField(name, value) {
    if(this[name]) {
      this[name].value = value;
    } else {
      this[name] = createHiddenField(this[`${name}NameValue`], value);
      this.formTarget.appendChild(this[name]);
    }
  }

  setTokenField(token) {
    this.setField('tokenInput', token.id);
  }

  setZipField(token) {
    this.setField('zipInput', token.card?.address_zip)
  }

  onCardChange(card) {
    if (card.error) {
      this.errorsTarget.textContent = card.error.message;
    }
    if (card.complete) {
      this.stripe.createToken(this.card).then(result => {
        if (result.error) {
          this.errorsTarget.textContent = result.error.message;
        } else {
          const { token } = result;

          this.errorsTarget.textContent = '';
          this.setTokenField(token);
          this.setZipField(token);
        }
      });
    }
  }
}
