import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  copy() {
    const textToCopy = this.element.dataset.clipboardCopyParam;
    
    navigator.clipboard.writeText(textToCopy)
    .then(() => {
        this.element.querySelector('span').textContent = 'Copied!';
      });
  }
}
