// Funny name for a controller, right?!
// 
// The purpose of this controller is to "kick" (like a donkey) 
// the script tags delivered via a turbo-stream.
// 
// Currently, when a script tag is delivered via turbo streams,
// it does not run when rendered to the dom.  This controller
// ensures the dang scripts run, man!
import { Controller } from '@hotwired/stimulus';

const scriptSelector = 'script';

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  connect() {
    this.replaceScripts();
  }

  replaceScripts() {
    const scriptElements = this.element.querySelectorAll(scriptSelector);
    scriptElements.forEach(element => this.activateScript(element));
  }

  activateScript(scriptElement) {
    const liveScript        = document.createElement('script');
    liveScript.textContent  = scriptElement.textContent;
    scriptElement.replaceWith(liveScript);
  } 
}