import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller in _aliquot_feed_set_up_prompt_modal.html.erb tells Stimulus to connect that element to the aliquot-feed-set-up-prompt-modal controller (i.e., this file)
    // The connect method is called automatically when the controller is connected.

    // This tells Stimulus to look for an element withs with:
    //- data-aliquot-feed-set-up-prompt-modal-target="modal" (store it in this.modalTarget)
    //- data-aliquot-feed-set-up-prompt-modal-target="closeButton" (store it in this.closeButtonTarget)
    //- data-aliquot-feed-set-up-prompt-modal-target="content" (store it in this.contentTarget)
    //- data-aliquot-feed-set-up-prompt-modal-target="thankYouMessage" (store it in this.thankYouMessageTarget)
    static targets = ["modal", "closeButton", "content", "thankYouMessage"]

    // As soon as Stimulus connects the controller, it automatically calls the connect method. 
    connect() {
        this.openModal();

        // Listen for the custom event
        // This event is fired from SMSForm.js when the user submits the form w/ their phone number
        window.addEventListener('smsFormSubmitted', this.handleSmsFormSubmitted.bind(this));
    }

    disconnect() {
        // Clean up the event listener
        window.removeEventListener('smsFormSubmitted', this.handleSmsFormSubmitted.bind(this));
    }

    openModal() {
        // Checks if it can find the modal target (the element with data-aliquot-feed-set-up-prompt-modal-target="modal"), which is the modal element in _aliquot_feed_set_up_prompt_modal.html.erb
        if (this.hasModalTarget) {
            // If found, it removes the 'hidden' class, making the modal visible.
            this.modalTarget.classList.remove('hidden')
            // Adds 'noscroll' to the body to prevent scrolling while the modal is open.
            document.body.classList.add('noscroll')
            // Creates an overlay to cover the entire screen.
            this.createOverlay()
        } else {
            console.log("Modal target not found")
        }
    }

    // This method is called when the user clicks the "Don't remind me again for a while" link in the modal
    // It sends a request to the server to update the user's aliquot_setup_reminded_at field to the current time
    dismissReminder(event) {
        event.preventDefault();

        axios({
            method: 'post',
            url: '/api/v1/users/dismiss_aliquot_setup_reminder',
            data: {
                //Authenticity token explanation:
                // In redesign2019.html.erb, there is a meta tag that looks like this: <%= csrf_meta_tags %>
                // csrf_meta_tags is a helper method that generates to HTML meta tags in the page's <head> section that look like this:
                //<meta name="csrf-param" content="authenticity_token">
                //<meta name="csrf-token" content="long_random_string_here">
                // The token is sent to the server where the UsersController's 'verify_authenticity_token'
                //...checks it to ensure the request is legitimate and not from a malicious source 
                authenticity_token: document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .finally(() => {
                // Close the modal regardless of success or failure
                this.close();
            });
    }

    // Method called when the smsFormSubmitted event is fired
    // The event listener is set up in the connect method
    // This event is fired from SMSForm.js when the user submits the form w/ their phone number
    handleSmsFormSubmitted(event) {
        // Update the close button visibility
        this.updateCloseButtonVisibility();

        // Hide the existing modal content
        if (this.hasContentTarget) {
            this.contentTarget.classList.add('hidden');
        }

        // Show the thank you message
        if (this.hasThankYouMessageTarget) {
            this.thankYouMessageTarget.classList.remove('hidden');
        }

        // Close the modal after 3 seconds
        setTimeout(() => {
            this.close();
        }, 3000);
    }

    updateCloseButtonVisibility() {
        // Updates the close button visibility
        // Shows the close button after the user submits the form to receive
        // a text message with The Aliquot setup link, allowing them to close the modal
        if (this.hasCloseButtonTarget) {
            this.closeButtonTarget.classList.remove('hidden');
        }
    }

    close() {
        this.modalTarget.classList.add('hidden')
        document.body.classList.remove('noscroll')
        this.removeOverlay()
    }

    createOverlay() {
        if (document.getElementsByClassName('overlay').length === 0) {
            const overlayEl = document.createElement('div')
            overlayEl.classList.add('overlay')
            document.body.appendChild(overlayEl)
        }
    }

    removeOverlay() {
        const overlays = Array.from(document.getElementsByClassName('overlay'))
        overlays.forEach(el => el.remove())
    }
}