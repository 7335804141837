import { Controller } from '@hotwired/stimulus';

const STATE_RELEASED    = 'released';
const STATE_GRABBED     = 'grabbed';
const STATE_DRAGGING    = 'dragging';
const DRAGGABLE_STATES  = [STATE_GRABBED, STATE_DRAGGING];

function clamp(value, min=0, max=1) {
  return Math.min(Math.max(value, min), max);
}

export default class extends Controller {
  static targets = ['track', 'slider'];
  static values = { 
    name:   String,
    value:  Number,
    state: {
      type: String,
      default: STATE_RELEASED
    }
  };

  get isManuallyMoving() {
    return DRAGGABLE_STATES.includes(this.stateValue);
  }

  grab(evt) {
    if(this.stateValue != STATE_RELEASED) return;

    this.stateValue = 'grabbed';
    this.valueValue = this.computeValue(evt);
  }

  drag(evt) {
    if(!this.isManuallyMoving) return;
    evt.preventDefault();
    
    this.stateValue = 'dragging';
    this.valueValue = this.computeValue(evt);
  }

  release(evt) {
    if(!this.isManuallyMoving) return;

    this.valueValue = this.computeValue(evt);
    this.stateValue = 'released';
  }

  computeValue(evt) {
    const rect = this.trackTarget.getBoundingClientRect();
    const { left, width } = rect;
    const pageX = evt.pageX - window.pageXOffset;
    const unboundValue = (pageX - left) / width;

    return clamp(unboundValue);
  }

  valueValueChanged(value) {
    this.sliderTarget.style.width = `${value * 100}%`;
    
    if(this.isManuallyMoving) {
      this.dispatch('change', { detail: { value, name: this.nameValue } });
    }
  }
}