import axios from 'axios';
import humps from 'humps';

export { default as EmailVerifier }     from './utils/EmailVerifier';
export { default as UsernameVerifier }  from './utils/UsernameVerifier';

// eslint-disable-next-line import/prefer-default-export
export const api = axios.create({
  baseURL: '/api/v1/',
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest
  ]
});

// eslint-disable-next-line import/prefer-default-export
export const checkEmail = email => {
  return new Promise((resolve, reject) => {
    axios
      .get('/email_typo_check/' + btoa(encodeURIComponent(email)))
      .then(({ data }) => {
        if (data.isUsernameValid) {
          resolve();
        } else {
          reject(data.suggestion);
        }
      });
  });
};
