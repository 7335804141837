import { Controller } from '@hotwired/stimulus';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const STATE_PAUSED  = 'paused';
const STATE_PLAYING = 'playing';

const { floor } = Math;

function formatTime(duration) {
  const seconds = floor(duration);
  const minutes = floor(seconds / 60);
  const hours   = floor(minutes / 60);

  const displayParts  = [minutes % 60, seconds % 60];
  
  if(hours > 0) {
    displayParts.unshift(hours);
  }

  return  displayParts
          .map(part => part.toString().padStart(2, '0'))
          .join(':');
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = [
    'player',
    'progress',
    'volume',
    'timeElapsed',
    'timeRemaining',
    'btnPlay',
    'btnPause'
  ];

  static values = {
    state: {
      type:     String,
      default:  STATE_PAUSED
    },
    progress: Number,
    volume:   Number,
    resetOnHide: {
      type:     Boolean,
      default:  true
    }
  };

  resetOnHide = (entries) => {
    const [lastEntry] = entries.slice(-1);
    if(!lastEntry) return;
    
    if(!lastEntry.isIntersecting) {
      const { boundingClientRect: { height, width } } = lastEntry;
      const isVisible = height > 0 || width > 0;

      if(!isVisible) {
        this.resetAudio();
      }
    }
  }

  initialize() {
    if(this.resetOnHideValue) {
      this.intersectionObserver = new IntersectionObserver(this.resetOnHide)
    }

    this.jumpToAudio = this.jumpToAudio.bind(this);
  }

  connect() {
    this.intersectionObserver?.observe(this.element);
    document.addEventListener('jumpToTime', this.jumpToAudio);
  }

  disconnect() {
    this.intersectionObserver?.unobserve(this.element);
    document.removeEventListener('jumpToTime', this.jumpToAudio);
  }

  // --------------------------------------------------------
  // Controls -> Player Methods
  // --------------------------------------------------------

  toggleAudio() {
    this.stateValue = this.stateValue === STATE_PAUSED
                      ? STATE_PLAYING
                      : STATE_PAUSED;
  }
  
  playAudio() {
    this.stateValue = STATE_PLAYING;
  }

  pauseAudio() {
    this.stateValue = STATE_PAUSED;
  }

  jumpToAudio(evt) {
    if (evt.params?.seconds !== undefined) {
      this.playerTarget.currentTime = evt.params.seconds;
    } else if (evt.detail?.time !== undefined) {
      this.playerTarget.currentTime = parseInt(evt.detail.time);
    }
  }

  updateAudio(evt) {
    const { detail: { name, value } } = evt;
    
    if(name === 'position') {
      this.playerTarget.currentTime = value * this.playerTarget.duration;
    } else if(name === 'volume') {
      this.playerTarget.volume = value;
    }
  }

  resetAudio() {
    this.playerTarget.currentTime = 0;
    this.stateValue               = STATE_PAUSED;
  }

  // --------------------------------------------------------
  // Player -> Controls Methods
  // --------------------------------------------------------

  initControls() {
    this.updateTimeControls();
    this.updateVolumeControls();

    this.dispatch('init', { 
      detail: { duration: this.playerTarget.duration } 
    });
  }

  updateTimeControls() {
    const { currentTime, duration } = this.playerTarget;
    const progress                  = currentTime / duration;
    this.progressValue              = progress;

    this.dispatch('progress', {
      detail: { progress }
    });
  }

  updateVolumeControls() {
    const { volume } = this.playerTarget;
    this.volumeValue = volume;
  }

  // --------------------------------------------------------
  // Value Change Handlers
  // --------------------------------------------------------

  stateValueChanged(state) {
    switch(state) {
      case STATE_PAUSED:
        this.btnPlayTarget.classList.remove('hidden');
        this.btnPauseTarget.classList.add('hidden');
        this.playerTarget.pause();

        break;
      case STATE_PLAYING:
        this.btnPlayTarget.classList.add('hidden');
        this.btnPauseTarget.classList.remove('hidden');
        this.playerTarget.play();

        break;
    }
  }

  progressValueChanged(progress) {
    const { currentTime, duration } = this.playerTarget;

    this.progressTarget.dataset.coreSliderComponentValueValue = progress;
    this.timeElapsedTarget.innerText                          = formatTime(currentTime);

    if(!isNaN(duration)) {
      this.timeRemainingTarget.innerText  = formatTime(duration - currentTime);
    }
  }

  volumeValueChanged(volume) {
    this.volumeTarget.dataset.coreSliderComponentValueValue = volume;
  }
}