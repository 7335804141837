import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['signIn', 'signUp'];

  connect() {
    this.signup();
  }

  signup(e) {
    e?.preventDefault();

    this.signInTarget.classList.add('hidden');
    this.signUpTarget.classList.remove('hidden');
  }

  signin(e) {
    e?.preventDefault();

    this.signUpTarget.classList.add('hidden');
    this.signInTarget.classList.remove('hidden');
  }
}
