import { Controller } from '@hotwired/stimulus';
import YouTubePlayer from 'youtube-player';

const YT_PLAYER_ID_PREFIX = 'youtube-player';

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets  = ['player'];
  static values = {
    playerId: String,
    autoplay: Number
  }

  initialize() {
    this.isPlayerReady = false;
  }
  
  connect() {

    // Establish bindings to relevant functions:
    this.initializePlayer = this.initializePlayer.bind(this);
    this.handleJump = this.handleJump.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);

    // listen for events coming from the episode_button Controller
    document.addEventListener('jumpToTime', this.handleJump);

    this.initializePlayer();
  }

  disconnect() {
    // Don't respond to these events after disconnection
    document.removeEventListener('jumpToTime', this.handleJump);
  }

  initializePlayer() {      
      this.player = YouTubePlayer(YT_PLAYER_ID_PREFIX + "-" + this.playerIdValue);
      this.player.on('ready', this.onPlayerReady);
      
      if(this.hasPlayerIdValue) {
        this.player.loadVideoById(this.playerIdValue);
      }
  }

  onPlayerReady(event) {
    this.isPlayerReady = true;
    if(this.autoplayValue == 1) {
      this.player.playVideo();
    } else {
      this.player.stopVideo();
    }
  }

  handleJump(event) {
    const time = event.detail.time;

    if(!this.player) {
      return console.log('No player instance');
    }

    if(!this.isPlayerReady) {
      return console.log('player not ready');
    }

    this.player.seekTo(time);
    this.player.playVideo();    
  }
}
