import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['url', 'upload', 'progress'];
  static values = { extension: String };

  connect() {
    this.element.classList.add('hidden');
    const that = this;

    axios
      .get('/admin/uploads', {
        params: { extension: this.extensionValue },
        withCredentials: true
      })
      .then(uploadData => {
        this.uploadUrl = uploadData.data.uploadUrl;
        this.publicUrl = uploadData.data.publicUrl;

        this.element.classList.remove('hidden');
      });

    this.uploadTarget.addEventListener('change', () => {
      if (this.uploadTarget.files.length === 0) {
        return;
      }

      const file = this.uploadTarget.files[0];

      axios
        .put(this.uploadUrl, file, {
          onUploadProgress: this.onUploadProgress.bind(that)
        })
        .then(() => {
          this.urlTarget.value = this.publicUrl;
          this.progressTarget.innerText = 'Done!';
        });
    });
  }

  onUploadProgress(progressEvent) {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    this.progressTarget.innerText =
      'Uploading... ' + percentCompleted.toString() + '%';
  }
}
