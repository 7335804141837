/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import copy from '../../../node_modules/copy-to-clipboard/index';

export default class PodcastFeedController extends Controller {
  static targets = ['link', 'smsForm'];

  static values = {
    token: String,
    url: String
  };

  copy(e) {
    copy(this.urlValue);
    const button = e.target;

    button.innerHTML = button.innerHTML.replace('Copy', 'Copied!');

    setTimeout(() => {
      button.innerHTML = button.innerHTML.replace('Copied', 'Copy');
    }, 5000);
  }

  urlValueChanged() {
    this.linkTarget.innerText = this.urlValue;
  }

  tokenValueChanged() {
    if (!this.tokenValue) return;
    this.updateSmsForm();
  }

  updateSmsForm() {
    const selector = '[data-react-class="views/SMSForm"]';
    const component = this.smsFormTarget.querySelector(selector);
    const componentProps = {
      ...JSON.parse(component.dataset.reactProps),
      podcastFeedToken: this.tokenValue
    };

    ReactRailsUJS.unmountComponents(selector);
    this.smsFormTarget.removeChild(component);
    component.dataset.reactProps = JSON.stringify(componentProps);
    this.smsFormTarget.appendChild(component);
    ReactRailsUJS.mountComponents(selector);
  }
}
