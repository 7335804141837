import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller="brevo-email-form" in _brevo_email_form.html.erb tells Stimulus to connect that element to this controller
    // The connect method is called automatically when the controller is connected.

    // This tells Stimulus to look for elements with data-brevo-email-form-target="form" and data-brevo-email-form-target="email" 
    // Store them in this.formTarget, this.emailTarget respectively
    static targets = ["form", "email"]

    // This tells Stimulus to look for a data attribute called data-brevo-email-form-registered-via-value
    // The value of this attribute will be automatically stored in this.registeredViaValue
    // In the HTML, we set it like this: data-brevo-email-form-registered-via-value="<%= @registered_via %>"
    // @registered_via is set in the episodes_controller.rb
    static values = { registeredVia: String }

    // As soon as Stimulus connects the controller, it automatically calls the connect method.
    connect() {
        // Add an event listener for form submission 
        this.formTarget.addEventListener('submit', this.handleSubmit.bind(this))

        // Add an event listener for the brevo:success event
        // This event fires in a script tag in _brevo_email_form.html.erb
        // When the event fires, it calls the handleSuccess method
        this.element.addEventListener('brevo:success', this.handleSuccess.bind(this))

        // This flag prevents the success event from being handled more than once
        this.successHandled = false
    }

    // This method is called when the form is submitted
    handleSubmit(event) {
        // Capture the email from the input field
        this.formTarget.dataset.submittedEmail = this.emailTarget.value
    }

    // This method is called when the brevo:success event fires
    // that event fires in a script tag in _brevo_email_form.html.erb
    // when the success message is inserted into the DOM
    handleSuccess() {
        if (this.successHandled) return
        this.successHandled = true

        // Prepare the parameters for the event
        const newsletterParams = {
            email: this.formTarget.dataset.submittedEmail || '',
            source: 'EPISODE_PAGE',
            registered_via: this.registeredViaValue,
            accept_newsletter: "1"
        }

        // Record the ahoy event
        ahoy.track('newsletter-subscription:create', newsletterParams)
    }
}