import { Controller } from '@hotwired/stimulus';


//
// "Jump to time" buttons need their own controller.
// They simply dispatch an event to the DOM and it is the responsibility
// of the episode player to listen for the event and respond to it.
// 
export default class extends Controller {

    jumpToTime(event) {
        // Gets the number of seconds to seek to
        const timeValue = event.currentTarget.dataset.episodePlayerTimeValue;
    
        // Dispatch a custom event to communicate with the episode-player controller
        const detail = { time: timeValue };
        document.dispatchEvent(new CustomEvent('jumpToTime', { detail, bubbles: true }));
    }
}