import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['player'];

  static values = {
    blockAfter: { type: Number, default: Infinity }
  };

  duration = 0;

  togglePlayer() {
    this.playerTarget.dispatchEvent(new CustomEvent('toggle-player'));
  }

  onInit(event) {
    const { detail: { duration } } = event;
    this.duration = duration;
  }

  onProgress(event) {
    const { detail: { progress } } = event;
    const current = progress * this.duration;

    if(current > this.blockAfterValue) {
      const limit = this.blockAfterValue / this.duration;

      this.playerTarget.dispatchEvent(
        new CustomEvent('block-player', { 
          detail:   { name: 'position', value: limit },
          bubbles:  true
        })
      );
    }
  }
}