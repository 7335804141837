import { Controller } from '@hotwired/stimulus';

export default class ReactMountController extends Controller {
  static targets = ['mountable'];

  connect() {
    ReactRailsUJS.mountComponents();
    // if(this.hasMountableTarget) {
    //   this.mountableTargets.forEach(target => {
    //     ReactRailsUJS.mountComponents(target)
    //   });
    //
    //   ReactRailsUJS.detectEvents();
    // }
  }

  disconnect() {
    ReactRailsUJS.unmountComponents();
    // if(this.hasMountableTarget) {
    //   this.mountableTargets.forEach(target => {
    //     ReactRailsUJS.unmountComponents(target);
    //   });
    // }
  }
}
