import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'toggler'];
  static values = {
    open:                   { type: Boolean,  default: false },
    togglerOpenedStateText: { type: String, default: '[HIDE]' },
    togglerClosedStateText: { type: String, default: '[SHOW]' }
  };

  currentEventDetail = {};

  connect() {
    document.addEventListener('keyup', this.hide.bind(this));
  }

  disconnect() {
    document.removeEventListener('keyup', this.hide.bind(this));
  }

  get contentElement() {
    return  this.hasContentTarget
            ? this.contentTarget
            : this.element;
  }

  show(evt) {
    this.captureEventParams(evt);
    evt?.preventDefault();
    this.openValue = true;
  }

  hide(evt) {
    this.captureEventParams(evt);

    if(evt) {
      if(evt.type === 'keyup' && evt.key !== 'Escape') {
        return;
      }
    }

    this.openValue = false;
  }

  toggle(evt) {
    this.captureEventParams(evt);
    this.openValue = !this.openValue;
  }

  openValueChanged(isOpen) {
    if(isOpen) {
      this.contentElement.classList.remove('hidden');
      
      if(this.hasTogglerTarget) {
        this.togglerTarget.textContent = this.togglerOpenedStateTextValue;
      }

      this.dispatch('show', { detail: this.currentEventDetail });
    } else {
      this.contentElement.classList.add('hidden');

      if(this.hasTogglerTarget) {
        this.togglerTarget.textContent = this.togglerClosedStateTextValue;
      }

      this.dispatch('hide', { detail: this.currentEventDetail });
    }
  }

  captureEventParams(evt) {
    if(evt !== null && evt !== undefined) {
      this.currentEventDetail = evt.params;
    }
  }
}
