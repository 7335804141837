import { Controller } from '@hotwired/stimulus';

// Selector for the checked amount input within the amount picker
const checkedAmountSelector = 'input[type="radio"][name$="[amount]"]:checked';
// Multiplier for calculating annual membership amount
const annualMembershipMultiplier = 9.6;
// Label to display when the form is processing
const workingButtonLabel = 'Processing...';

// --------------------------------------------------------
// Utilities
// --------------------------------------------------------

function formattedAmount(amount, isAnnual, discounts) {
  const dollarAmount = parseInt(amount) / 100;

  // Use case for discountAlreadyApplied to be true:
  // When form is shown on /crowdsponsor/earlybird, and the amount selected is $10
  // If we're already giving them a discount, then we multiply by 12 not 9.6
  // 
  // The discount array (defined in discounted_membership_form_component.rb) looks like this:
  // [
  //   {
  //     "from": 1500,
  //     "to": 1000,
  //   }
  // ]
  //
  // Check if the amount matches any 'to' value in the discounts array
  const discountAlreadyApplied = discounts.some(discount => discount.to.toString() === amount);

  const multiplier = isAnnual ? (discountAlreadyApplied ? 12 : annualMembershipMultiplier) : 1;

  const totalAmount = dollarAmount * multiplier;

  return totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets = ['amountPicker', 'donationTypeCheckbox', 'submitButton', 'annualSaving'];

  static values = {
    buttonLabelBase: String,
    buttonText: String,
    selectedAmount: String,
    isAnnualSubscription: Boolean,
    discounts: Array,
    defaultSelectedAmount: String, // Passed in from membership_form_component.html.erb for the ama_promo_in_modal field test
    amaPromoInModal: Boolean, // Passed in from membership_form_component.html.erb for the ama_promo_in_modal field test
    isForEpisodeSummaryAlternativeFieldTest: Boolean // Passed in from membership_form_component.html.erb for the episode_summary_alternative field test
  }

  // Called when the controller is connected to the DOM
  // Initializes the selected amount and updates the button label
  connect() {
    // Amount picker is present
    // This means data-membership-form-target="amountPicker" is present in membership_form_component.html.erb
    if (this.hasAmountPickerTarget) {
      // Get the value of the checked amount input
      const defaultValue = this.amountPickerTarget.querySelector(checkedAmountSelector)?.value;

      // If the defaultValue is not null, set the selectedAmountValue
      if (defaultValue) {
        this.selectedAmountValue = defaultValue;
      }

      // Update the "Save 20%" label visibility
      this.updateAnnualSavingVisibility();
    } else {
      // Amount picker is hidden (for the ama_promo_in_modal field test)
      // Set selectedAmountValue to defaultSelectedAmountValue or '1500' as default
      this.selectedAmountValue = this.defaultSelectedAmountValue || '1500';
    }
  }

  // Called when the amount is changed in the amount picker.
  onAmountChanged(event) {
    this.selectedAmountValue = event.target.value;
    this.updateAnnualSavingVisibility()
  }

  // Called when the donation type (annual/monthly) is toggled
  onDonationTypeChanged(event) {
    this.isAnnualSubscriptionValue = event.target.checked;
    this.updateAnnualSavingVisibility()
  }

  // Called when the form is submitted
  // Disables the submit button and updates the button text to "Processing..."
  onSubmit() {
    this.submitButtonTarget.disabled = true;
    this.buttonTextValue = workingButtonLabel;
  }

  // Observer method called when the selected amount is changed
  // Updates the button label with the formatted amount
  selectedAmountValueChanged(amount) {
    if (amount) {
      const currencyAmount = formattedAmount(amount, this.isAnnualSubscriptionValue, this.discountsValue);
      this.updateButtonLabel(currencyAmount);
    }
  }

  // Observer method called when isAnnualSubscriptionValue changes
  // Updates the button label accordingly
  isAnnualSubscriptionValueChanged(isAnnual) {
    if (this.selectedAmountValue) {
      const currencyAmount = formattedAmount(this.selectedAmountValue, isAnnual, this.discountsValue);
      this.updateButtonLabel(currencyAmount);
    }
  }

  // Observer method called when buttonTextValue changes
  // Updates the submit button's label
  buttonTextValueChanged(label) {
    this.submitButtonTarget.value = label;
  }

  // Updates the submit button's label with the formatted amount.
  updateButtonLabel(formattedAmount) {
    // If this is for the ama_promo_in_modal field test or the episode_summary_alternative field test
    if (this.amaPromoInModalValue || this.isForEpisodeSummaryAlternativeFieldTestValue) {
      // Determine the subscription period based on the isAnnualSubscriptionValue
      const period = this.isAnnualSubscriptionValue ? 'per year' : 'per month';
      this.buttonTextValue = `Sign up for ${formattedAmount} ${period} →`;

    } else {
      // Default behavior
      this.buttonTextValue = `${this.buttonLabelBaseValue} for ${formattedAmount}`;
    }
  }

  // This hides the "Save 20%" text over the donation type toggle for the annual option if the amount selected is $10 (selectedAmount = 1000)
  // This is because we don't apply a discount for the annual subscription in this case (as we're already giving them a discount)
  updateAnnualSavingVisibility() {
    // If the amount picker is hidden, this is not a situation where we will be applying a discount, so we don't need to update the annual saving visibility
    if (!this.hasAmountPickerTarget) {
      return;
    }

    const selectedAmount = parseInt(this.amountPickerTarget.querySelector('input[type="radio"]:checked').value)

    if (this.hasAnnualSavingTarget) {
      // If the selected amount is $10 (selectedAmount = 1000), we hide the "Save 20%" text
      //
      // Check if the selected amount matches any discount's 'to' value
      // The discounts array is defined in discounted_membership_form_component.rb
      // We could just say if (selectedAmount === 1000), but this is more future-proof
      if (this.discountsValue.some(discount => discount.to === selectedAmount)) {
        this.annualSavingTarget.classList.add('hidden')
      } else {
        this.annualSavingTarget.classList.remove('hidden')
      }
    }
  }
}