import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller in _delinquent_subscription_modal.html.erb tells Stimulus to connect that element to the delinquent-subscription-modal controller (i.e., this file)
    // The connect method is called automatically when the controller is connected.

    // This tells Stimulus to look for an element with data-delinquent-subscription-modal-target="modal" and store it in this.modalTarget
    static targets = ["modal"]

    // This tells Stimulus to look for a data attribute called data-delinquent-subscription-modal-force-redirect-value
    // The value of this attribute will be automatically converted to a boolean and stored in this.forceRedirectValue
    // In the HTML, we set it like this: data-delinquent-subscription-modal-force-redirect-value="<%= @user_is_trying_to_load_premium_content %>"
    // @user_is_trying_to_load_premium_content is in application_controller.rb
    static values = {
        forceRedirect: Boolean
    }

    // As soon as Stimulus connects the controller, it automatically calls the connect method. 
    connect() {
        this.openModal()
    }

    openModal() {
        // Checks if it can find the modal target (the element with data-delinquent-subscription-modal-target="modal"), which is the modal element in _delinquent_subscription_modal.html.erb
        if (this.hasModalTarget) {
            // If found, it removes the 'hidden' class, making the modal visible.
            this.modalTarget.classList.remove('hidden')
            // Adds 'noscroll' to the body to prevent scrolling while the modal is open.
            document.body.classList.add('noscroll')
            // Creates an overlay to cover the entire screen.
            this.createOverlay()
        } else {
            console.log("Modal target not found")
        }
    }

    close() {
        // If the forceRedirectValue is true, the modal is can't be closed and the user is redirected to the billing portal when the timer ends or they click the 'Update Billing Information' button.
        if (!this.forceRedirectValue) {
            this.modalTarget.classList.add('hidden')
            document.body.classList.remove('noscroll')
            this.removeOverlay()
        }
    }

    createOverlay() {
        if (document.getElementsByClassName('overlay').length === 0) {
            const overlayEl = document.createElement('div')
            overlayEl.classList.add('overlay')
            // If the forceRedirectValue is true, clicking the overlay should not close the modal.
            if (!this.forceRedirectValue) {
                overlayEl.addEventListener('click', () => this.close())
            }
            document.body.appendChild(overlayEl)
        }
    }

    removeOverlay() {
        const overlays = Array.from(document.getElementsByClassName('overlay'))
        overlays.forEach(el => el.remove())
    }
}