import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Purpose of this controller: Manage the display of testimonials on the sales page,
  // allowing users to view all testimonials by clicking a button.

  // When the page loads, Stimulus looks for elements with data-controller="testimonials"
  // This tells Stimulus to connect those elements to this controller

  // This tells Stimulus to look for elements with data-testimonials-target="container" and "button"
  // These elements will be accessible via this.containerTarget and this.buttonTarget
  static targets = ["container", "button"]

  // This method is called when the controller connects to the DOM
  connect() {
    // Set the initial number of testimonials to display
    this.displayedCount = 3
  }
  
  // This method is called when the "Show All Testimonials" button is clicked
  // This is because of the data-action="click->testimonials#showAll" attribute in the HTML
  showAll() {
    // Query all testimonial elements within the container
    this.containerTarget.querySelectorAll('.qa-sales-page__testimonials__testimonial').forEach(el => {
      // Remove the 'hidden' class from each testimonial, making them all visible
      el.classList.remove('hidden')
    })

    // Check if the button target exists (it should, but it's good to be safe)
    if (this.hasButtonTarget) {
      // Add the 'd-none' class to the button, hiding it from view
      // We use 'd-none' instead of 'hidden' to ensure it overrides other display properties
      this.buttonTarget.classList.add('d-none')
    }
  }
}