import { Controller } from '@hotwired/stimulus';

// --------------------------------------------------------
// Internals
// --------------------------------------------------------

const TAB_LABEL_SELECTOR = '[data-tab-label]';

// --------------------------------------------------------
// Controller Definition
// --------------------------------------------------------

export default class extends Controller {
  static targets  = ['largeLabelContainer', 'content'];
  static values   = {
    selectedIndex: {
      type:     Number,
      default:  0
    }
  };

  onTabSelect(evt) {
    const { target } = evt;
    this.selectedIndexValue = target.value;
  }

  onTabClick(evt) {
    evt.preventDefault();
    const { target } = evt;

    this.selectedIndexValue = target.dataset.index;
  }

  selectedIndexValueChanged(selectedIndex, lastSelectedIndex) {
    
    // Set selection on <a>'s
    const links = this.largeLabelContainerTarget.querySelectorAll(TAB_LABEL_SELECTOR);
    const lastSelectedLink  = links.item(lastSelectedIndex);
    const selectedLink      = links.item(selectedIndex);

    lastSelectedLink?.classList.remove('is-active');
    lastSelectedLink?.classList.add('tab-default');

    selectedLink?.classList.remove('tab-default');
    selectedLink?.classList.add('is-active');

    // Set selected content.
    this.contentTargets[lastSelectedIndex]?.classList.add('hidden');
    this.contentTargets[selectedIndex]?.classList.remove('hidden');
  }
}